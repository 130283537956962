import { useEffect } from "react";

import { useStore } from "hooks/useStore";
import { observer } from "mobx-react";
import { Game } from "models/Game";
import { GameBoard, Player } from "utils/GameBoard";
import './style.scss';
import { CellButton, CellContainer, GameContainer } from "./styled";


export const GameBoardComponent = observer(({className}: {className?: string}) => {
  const { gamesStore, isConnected, GAME_LENGTH } = useStore();
  const { hoveredGame } = gamesStore;
  const { temporaryStep } = hoveredGame || {} as Game;
  const gameLength = hoveredGame?.gameLength || GAME_LENGTH;
  const gameBoard: GameBoard = GameBoard.getInstance(gameLength);

  useEffect(() => {
    if (hoveredGame) {
      gameBoard.setCells(hoveredGame?.board);      
      const realTurn = hoveredGame.turn === 2 ? 1 : 2;
      //@ts-ignore
      gameBoard.turn = realTurn;
    }
  }, [hoveredGame, hoveredGame?.board]);


  if (!hoveredGame) {
    return null;
  }

  const buttons: any[] = [];

  for (let i = 0; i < gameLength; i = i + 1) {
    for (let j = 0; j < gameLength; j = j + 1) {
      let className = "btn-free";
      if (hoveredGame?.board[i][j] === Player.WHITE) {
        className = `btn-selected btn-selected-white player-${Player.WHITE}`;
      } else if (hoveredGame?.board[i][j] === Player.BLACK) {
        className = `btn-selected btn-selected-black player-${Player.BLACK}`;
      }
      if (i === temporaryStep?.row && j === temporaryStep.column) {
        className += " active"
      }

      // If game has a winner, highlight the winner row's cells
      if (hoveredGame && hoveredGame?.winnerIndex !== Player.NONE && hoveredGame.winnerCells.length >= 5) {
        hoveredGame.winnerCells.forEach(winnerCell => {
          if (winnerCell.row === i && winnerCell.column === j) {
            className += " btn-winner-cell";
          }
        });
      }

      // Every time Highlight last move:
      const { lastPlayedCell } = hoveredGame;
      if (
        lastPlayedCell &&
        lastPlayedCell.row === i &&
        lastPlayedCell.column === j &&
        hoveredGame?.board[i][j] !== Player.NONE
      ) {
        className += " btn-last-played";
      } else {
        className = className.replace(" btn-last-played", "");
      }

      buttons.push(
        <CellContainer length={gameLength} key={`btn-${i}-${j}`}>
          <CellButton
            isActive={i === temporaryStep?.row && j === temporaryStep?.column}
            turn={hoveredGame.turn}
            className={className}
            onClick={hoveredGame.clickCell}
            data-row={i}
            data-column={j}
            disabled={
              !isConnected ||
              !hoveredGame.takingPart ||
              hoveredGame.isFinished || 
              hoveredGame?.board[i][j] !== Player.NONE || 
              hoveredGame.gameIsNotActive
            }
          />
        </CellContainer>
      );
    }
  }

  return <GameContainer className={`game-board ${className || ''}`} length={gameLength}>{buttons}</GameContainer>;
});
