import '@rainbow-me/rainbowkit/styles.css';
import ReactDOM from "react-dom";
import "./index.css";

import { QueryClient } from "@tanstack/query-core";
import { QueryClientProvider } from '@tanstack/react-query';

import { RainbowKitProvider, Theme, darkTheme, AvatarComponent } from '@rainbow-me/rainbowkit';
import { velas } from 'App/config';
import { ToastContainer } from 'react-toastify';
import { WagmiProvider } from 'wagmi';
import { structuralSharing } from "wagmi/query";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
import { config } from "./wagmiConfig";
import HexAvatar from 'components/Avatar';

const CustomAvatar: AvatarComponent = ({ address, size }) => {
  return (
    <HexAvatar
      address={address}
      width={size}
      height={size}
    />
  );
};

const theme: Theme = {
  ...darkTheme(),
  blurs: {
    modalOverlay: "blur(4px)",
  },
  colors: {
    ...darkTheme().colors,
    modalBackground: "#37281a",
    modalBorder: "rgba(255,255,255,0.1)",
    accentColor: "#ffaa19",
    accentColorForeground: "white",
    modalBackdrop: "rgba(0,0,0,0.8)",
    actionButtonSecondaryBackground: "transparent",
    connectButtonBackground: "linear-gradient(181.21deg, rgba(255, 255, 255, 0.4) 1.04%, rgba(255, 255, 255, 0) 57%), linear-gradient(180deg, #D38433 0%, #B5702B 100%)",
    connectButtonInnerBackground: "transparent"
  },
  radii: {
    ...darkTheme().radii,
    modal: "8px",
    menuButton: "6px",
    actionButton: "6px",
    connectButton: "6px",
  },
  fonts: {
    ...darkTheme().fonts,
    body: "Lilita One, sans-serif",
  },
};

// const queryClient = new QueryClient();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      structuralSharing,
    },
  },
});

ReactDOM.render(
  <WagmiProvider config={config}>
    <QueryClientProvider client={queryClient}>
      <RainbowKitProvider avatar={CustomAvatar} coolMode={true}
        locale={'en'}
        modalSize="compact"
        initialChain={velas.id}
        theme={theme}
      >
        <App />
      </RainbowKitProvider>
    </QueryClientProvider>
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
  </WagmiProvider>
  , document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
