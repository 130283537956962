import { SearchModel } from "models/SearchModel";
import { Player } from "utils/GameBoard";

export type HexAddress = string;

export type GameRaw = GameInterface;

type Move = {
  playerAddress: string;
  prevMoveTs: bigint;
  currentMoveTs: bigint;
  x: bigint;
  y: bigint;
}
export interface GameInterface {
  id: bigint;
  board: Array<Array<number>>
  status: number; // Possible game statuses
  players: {
    expectedPlayer2: string;
    player1: string; // Player 1 address
    player2?: string | null; // Player 2 address (if any)
  }
  winner?: string | null; // Winner (if game is finished)
  claims: {
    player1BetClaimed: boolean;
    player2BetClaimed: boolean;
    rewardClaimed: boolean;
  }
  currentPlayer: Player; 
  gameLength: number;
  betAmount: bigint;
  gameType: GameTypes;
  gameStartedAt: bigint;
  lastPlayedCell: {
    row: number;
    column: number;
  };
  movesArray: Move[];
}

export enum GameTypes {
  BLITZ = 0,
  BULLET = 1,
  RAPID = 2,
}

export enum Colors {
  BLACK = 0,
  WHITE = 1,
}

export enum Opponent {
  EVERYONE = 0,
  ADDRESS = 1,
}

export enum GameStatus {
  NOT_FINISHED = 0,
  FINISHED = 1,
  CANCELED = 2,
}

export enum BetType {
  NO_BET = 0,
  SET_A_BET = 1,
}

export enum GameSize {
  DEFAULT = 19,
  SMALL = 5,
  MEDIUM = 25,
  BIG = 30, 
}

export const emptyAddress = "0x0000000000000000000000000000000000000000";

export type HexString = `0x${string}`;

export interface PlayerContainerProps {
  playerAddress: HexAddress | undefined;
  playerShortAddress: HexAddress;
  playerIndex: 1 | 2;
  timeLeft: number;
  totalGameTime: number;
  rating: number | null;
  winner: string;
}

export interface TransactionLog {
  address: HexAddress;
  args: {};
  blockHash: HexAddress;
  blockNumber: HexAddress;
  data: string;
  eventName: string;
  logIndex: number;
  removed: boolean;
  topics: [];
  transactionHash: string;
  transactionIndex: number;
}

export interface GamesListProps {
  searchModel: SearchModel;
}
