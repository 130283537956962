import { readContract } from '@wagmi/core';
import { config, gomokuContract } from "App/config";
import { action, computed, makeObservable, observable } from "mobx";
import { HexString } from "types";
import { abi } from "web3/contract/abi";
import { RootStore } from "./RootStore";

interface NicknameData {
  nickname: string;
  playerAddress: HexString;
}

export class ProfileStore {
  @observable myAddress: HexString | undefined = undefined;
  @observable isOpenedModal = false;
  @observable nickname = '';
  @observable isLoading = false;
  @observable allNicknames = [] as NicknameData[];
  @observable errorMessage = '';

  constructor(private appStore: RootStore) {
    makeObservable(this);
    this.fetchAllNickNames();
  }

  @computed 
  get profileIsLoading() {
    return this.appStore.gamesStore.isLoading;
  }

  @computed
  get showSetNicknameButton() {
    return this.appStore.myAddress === this.appStore.membersStore.currentMemberAddress;
  }

  @computed
  get nicknamesMap(): Record<string, string> {
    return this.allNicknames.reduce((acc, curr) => {
      acc[curr.playerAddress] = curr.nickname;
      return acc;
    }, {} as Record<string, string>);
  }

  @computed
  get hasError() {
    return !!this.errorMessage;
  }

  @computed 
  get myNickname() {
    if (!this.myAddress) return '';
    return this.nicknamesMap[this.myAddress];
  }

  @computed
  get myStats() {
    if (!this.myAddress) return null;
    return this.appStore.leaderboardStore.stats[this.myAddress];
  }

  @computed
  get myGames() {
    return this.appStore.gamesStore.myGamesList;
  }

  @computed
  get myGamesCount() {
    return this.myGames.length;
  }

  @computed
  get statsData() {
    if (!this.appStore.membersStore.currentMemberAddress) return null;
    return this.appStore.leaderboardStore.getPlayerStatsProfilePage(this.appStore.membersStore.currentMemberAddress);
  }

  @action.bound
  openModal() {
    this.nickname = this.myNickname;
    this.errorMessage = '';
    this.isOpenedModal = true;
  }

  @action.bound
  closeModal() {
    this.nickname = this.myNickname;
    this.isOpenedModal = false;
  }

  @action.bound
  onTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.nickname = event.target.value;
    this.validateNickName();
  }

  @action.bound
  validateNickName() {
    if (this.nickname.trim() === '') {
      this.errorMessage = 'Nickname cannot be empty.';
    } else if (this.nickname.length > 30) {
      this.errorMessage = 'Nickname cannot exceed 30 characters.';
    } else if (!/^[A-Za-z0-9_]*$/.test(this.nickname)) {
      this.errorMessage = 'Nickname can only contain letters, numbers, and underscores.';
    } else {
      this.errorMessage = '';
    }
  }

  @action.bound
  async onSaveNickname() {
    if (this.hasError) {
      return;
    }
    if (this.myNickname?.trim() === this.nickname?.trim()) {
      return;
    }
    if (this.isLoading) return;
    if (this.appStore.writeContract) {
      this.isLoading = true;
      const { writeContractAsync } = this.appStore.writeContract;
      try {
        const hash = await writeContractAsync({
          abi,
          address: gomokuContract,
          functionName: 'setNickname',
          args: [this.nickname],
        });
        if (hash) {
          this.appStore.notify.showSuccess('Nickname was updated');
          this.closeModal();
          setTimeout(() => {
            this.appStore.profileStore.fetchAllNickNames();
          }, 1000);
        }
      } catch (error) {
        this.appStore.notify.handleError(error);
      }
      finally {
        this.isLoading = false;
        this.fetchAllNickNames();
      }
    }
  }

  @action.bound
  async fetchAllNickNames() {
    const result = await readContract(config, {
      abi,
      address: gomokuContract,
      functionName: 'getAllNicknames',
    }) as NicknameData[];
    console.log('fetchAllNickNames', result);
    this.allNicknames = result;
  }
  
}