import { GamesTable } from "components/GamesTable";
import { useStore } from "hooks/useStore";
import { observer } from "mobx-react";
import { GamesTableModel } from "models/GamesTableModel";
import './style.scss';


export const AllGamesList = observer(() => {
  const { gamesStore } = useStore();
  const gamesAlreadyJoined = new GamesTableModel(gamesStore.gamesAlreadyJoinedList);
  const gamesToJoin = new GamesTableModel(gamesStore.gamesToJoinList);

  return (
    <div className="games-sections">
      <GamesTable gamesModel={gamesToJoin} searchModel={gamesStore.searchModel} />
      <div>
        <div className="title"><h2>All Games</h2></div>
        <GamesTable gamesModel={gamesAlreadyJoined} searchModel={gamesStore.searchModel} />
      </div>
    </div>
  );
});