import { ConnectButton } from '@rainbow-me/rainbowkit';
import { velas } from 'App/config';
import HexAvatar from 'components/Avatar';
import { useStore } from 'hooks/useStore';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAccount, useBalance, usePublicClient } from 'wagmi';
import LogoDark from '../../assets/imgs/logo-dark.svg';
import Logo from '../../assets/imgs/logo.svg';
import LogoShort from '../../assets/imgs/logo-short.svg';
import LogoDarkShort from '../../assets/imgs/logo-dark-short.svg';
import '../../pages/Games/style.scss';
import './style.scss';

export const Header = () => {
  const store = useStore();
  const { address, isConnected, chain } = useAccount();
  const publicClient = usePublicClient();
  const { profileStore, themeStore } = store;

  const isWrongNetwork = chain?.id !== velas.id;
  const isDarkTheme = themeStore.themeId === 'dark';

  const { refetch } = useBalance({
    address,
  });
  useEffect(() => { profileStore.myAddress = address; }, [address]);

  // Refresh ConnectButton balance
  useEffect(() => {
    if (publicClient && address) {
      const handleBlock = async () => {
        await refetch(); // Refresh balance on every new block
      };

      const unsubscribe = publicClient.watchBlocks({
        onBlock: handleBlock, // Block listener
      });

      return () => unsubscribe();
    }
  }, [publicClient, address, refetch]);

  return (
    <div className='header'>
       <img
        className={`logo desktop-logo`}
        src={isDarkTheme ? LogoDark : Logo}
        alt="logo"
        onClick={() => store.navigate('/')}
      />
      <img
        className={`logo mobile-logo`}
        src={isDarkTheme ? LogoDarkShort : LogoShort}
        alt="logo-short"
        onClick={() => store.navigate('/')}
      />
      <div className="right-side">
        <ConnectButton showBalance={true} accountStatus={'address'}/>
        {isConnected && !isWrongNetwork && (
          <Link className="address-value" to={`/member/${address}`}>
            <HexAvatar address={address} width={35} height={35} />
          </Link>
          // <Button onClick={profileStore.openModal} className="btn-profile">
          //   <img src={Action} alt='player' width={20} />
          //   @name
          // </Button>
        )}
      </div>
    </div>
  );
};
