import { action, computed, makeObservable, observable } from "mobx";


export enum GamesTabs {
  ROOMS = 'rooms',
  MY_ROOMS = 'my_rooms',
  LEADERBOARD = 'leaderboard',
}

type Item = {
  name: string;
  route: string;
}

type Route = string;
export class TabStore {

  @observable _items: Item[] = [];
  @observable _activeTab = '';
  @observable animating = false;

  constructor() {
    makeObservable(this);
  }

  @computed
  get activeTab(): Route | undefined {
    return this._activeTab;
  }

  @action setActiveTab = (tab: string) => {
    this._activeTab = tab;
  };

  @action setAnimating = (isAnimating: boolean) => {
    this.animating = isAnimating;
  }

}