import { GamesTable } from "components/GamesTable";
import { useStore } from "hooks/useStore";
import { observer } from "mobx-react";
import { GamesTableModel } from "models/GamesTableModel";
import { useEffect, useRef } from "react";



export const MyGamesList = observer(() => {
  const { gamesStore } = useStore();
  const gamesTableModel = useRef(new GamesTableModel(gamesStore.myGamesList)).current;

  useEffect(() => {
    gamesTableModel.setGames(gamesStore.myGamesList);
  }, [gamesStore.myGamesList, gamesTableModel]);

  return <GamesTable gamesModel={gamesTableModel} searchModel={gamesStore.searchModel} />;
});