import { formatSeconds, shortenAddress } from "helpers";
import { computed, makeObservable } from "mobx";
import { Game } from "models/Game";
import { emptyAddress, GameStatus } from "types";
import { RootStore } from "./RootStore";

type PlayerAddress = string;
export type Scores = { 
  wins: number; 
  loses: number; 
  drafts: number; 
  score: number; 
  spentTime: number; 
  gamesCount: number; 
  winPercentage: number;
  currentRating: number;
};
const DEFAULT_SCORE = 1000;

interface PlayerScore {
  hexAddress: string;
  address: string;
  timeSpent: string;
  gamesCount: number;
  winPercentage: number;
  wins: number;
  loses: number;
  draws: number;
  score: number;
}
export class LeaderboardStore {
  K = 32;

  constructor(private appStore: RootStore) {
    makeObservable(this);
  }

  @computed
  get players() {
    return this.playersSortedStats;
  }

  @computed
  get tableRowsData(): PlayerScore[] {
    return this.players.map(([hexAddress, scores]) => {
      return {
        hexAddress,
        address: this.appStore.profileStore.nicknamesMap[hexAddress] || shortenAddress(hexAddress, 9),
        timeSpent: formatSeconds(scores.spentTime),
        gamesCount: scores.gamesCount,
        winPercentage: Math.ceil(scores.winPercentage),
        wins: scores.wins,
        loses: scores.loses,
        draws: scores.drafts,
        score: scores.score,
      };
    })
  }

  private getPlayerSpentTime(game: Game, player: PlayerAddress) {
    const playerMoves = player === game.player1 ? game.movesOfPlayer1 : game.movesOfPlayer2;
    return playerMoves.reduce((acc, move) => acc + (move.currentMoveTs - move.prevMoveTs), BigInt(0));
  }

  @computed
  get playersSpentTimePlaying(): Record<PlayerAddress, number> {
    const map = {} as Record<PlayerAddress, number>;
    const gamesFinished = this.appStore.gamesStore.allGamesList.filter(game => game.status === GameStatus.FINISHED);
    for (const game of gamesFinished) {
      const player1TimeSpent = this.getPlayerSpentTime(game, game.player1);
      const player2TimeSpent = this.getPlayerSpentTime(game, game.player2);
      if (!map[game.player1]) {
        map[game.player1] = 0;
      }
      map[game.player1] += Number(player1TimeSpent);
      if (!map[game.player2]) {
        map[game.player2] = 0;
      }
      map[game.player2] += Number(player2TimeSpent);
    }
    return map;
  }

  @computed
  get playersSortedStats() {
    return [...Object.entries(this.stats)].sort((a, b) => b[1].score - a[1].score);
  }

  getPlayerStats(address: string) {
    return this.stats[address];
  }

  getPlayerStatsProfilePage(address: string): Omit<PlayerScore, 'hexAddress' | 'address'> {
    const statsData = this.stats[address];
    return {
      timeSpent: formatSeconds(statsData?.spentTime || 0),
      gamesCount: statsData?.gamesCount || 0,
      winPercentage: Math.ceil(statsData?.winPercentage || 0),
      wins: statsData?.wins || 0,
      loses: statsData?.loses || 0,
      draws: statsData?.drafts || 0,
      score: statsData?.score || 0,
    };
  }

  getPlayerScore(address: string) {
    return this.getPlayerStats(address)?.score;
  }

  @computed
  get stats(): Record<string, Scores> {
    const map = {} as Record<string, Scores>;
    const games = this.appStore.gamesStore.allGamesList.filter(game => game.status !== GameStatus.CANCELED);
    for (const game of games) {
      const opponentIsPresent = game.player2 !== emptyAddress;

      let win1 = 0;
      let looses1 = 0;
      let draw1 = 0;
      let win2 = 0;
      let looses2 = 0;
      let draw2 = 0;
      const player1Stats = map[game.player1] || {} as Record<string, Scores>;
      const player2Stats = map[game.player2] || {} as Record<string, Scores>;
      
      player1Stats.wins = player1Stats.wins || 0;
      player2Stats.wins = player2Stats.wins || 0;
      player1Stats.loses = player1Stats.loses || 0;
      player2Stats.loses = player2Stats.loses || 0;
      player1Stats.drafts = player1Stats.drafts || 0;
      player2Stats.drafts = player2Stats.drafts || 0;

      player1Stats.gamesCount = player1Stats.gamesCount || 0;
      player2Stats.gamesCount = player2Stats.gamesCount || 0;

      player1Stats.winPercentage = player1Stats.winPercentage || 0;
      player2Stats.winPercentage = player2Stats.winPercentage || 0; 

      player1Stats.score = player1Stats.score || DEFAULT_SCORE;
      player2Stats.score = player2Stats.score || DEFAULT_SCORE;
       
      if (game.winner === game.player1 && opponentIsPresent) {
        player1Stats.wins = player1Stats.wins + 1;
        player2Stats.loses = player2Stats.loses + 1;
        win1 = 1;
        looses1 = 0;
        draw1 = 0;
        win2 = 0;
        looses2 = 1;
        draw2 = 0;
      } else if (game.winner === game.player2 && opponentIsPresent) {
        player2Stats.wins = player2Stats.wins + 1;
        player1Stats.loses = player1Stats.loses + 1;
        win1 = 0;
        looses1 = 1;
        draw1 = 0;
        win2 = 1;
        looses2 = 0;
        draw2 = 0;
      } else if (game.isDraft && opponentIsPresent) {
        player2Stats.drafts = player2Stats.drafts + 1;
        player1Stats.drafts = player1Stats.drafts + 1;
        win1 = 0;
        looses1 = 0;
        draw1 = 1;
        win2 = 0;
        looses2 = 0;
        draw2 = 1;
      }
      const spentTimePlayer1 = this.playersSpentTimePlaying[game.player1] || 0;
      const spentTimePlayer2 = this.playersSpentTimePlaying[game.player2] || 0;
      player1Stats.spentTime = spentTimePlayer1;
      player2Stats.spentTime = spentTimePlayer2;

      player1Stats.gamesCount = opponentIsPresent
        ? player1Stats.gamesCount + 1
        : player1Stats.gamesCount + 0;
      player2Stats.gamesCount = opponentIsPresent 
        ? player2Stats.gamesCount + 1
        : player2Stats.gamesCount + 0;
      if (game.player1 === '0x513e7b8b8c805d8957515154F31682493B7AbB8C' || game.player2 === '0x513e7b8b8c805d8957515154F31682493B7AbB8C') {
        console.log('gameee', game);
      }

      player1Stats.winPercentage = player1Stats.gamesCount > 0
        ? (player1Stats.wins / player1Stats.gamesCount) * 100
        : 0;
      if (opponentIsPresent) {
        player2Stats.winPercentage =  player2Stats.gamesCount > 0 
          ? (player2Stats.wins / player2Stats.gamesCount) * 100
          : 0;
      }

      const player1CurrentScore = player1Stats.score;
      const player2CurrentScore = player2Stats.score;

      const player1CalculatedScore = this.calculateScore({wins: win1, loses: looses1, drafts: draw1}, player1CurrentScore, player2CurrentScore);
      player1Stats.score = player1CalculatedScore;
      player2Stats.score = this.calculateScore({wins: win2, loses: looses2, drafts: draw2}, player2CurrentScore, player1CurrentScore);

      map[game.player1] = player1Stats;
      if (opponentIsPresent) {
        map[game.player2] = player2Stats;
      }
    }

    // this.updateScores(map);
    return map;
  }

  // private updateScores = (map: Record<string, Scores>) => {
  //   Object.keys(map).forEach(userId => {
  //     const userScores = map[userId];
  //     // userScores.score = this.calculateScore(userScores);
  //   });
  // };

  private calculateScore = (
    stats: Pick<Scores, 'wins' | 'loses' | 'drafts'>,
    playerRating: number,
    opponentRating: number
  ): number => {
    const { wins, loses, drafts } = stats;
    const totalGames = wins + loses + drafts;

    if (totalGames === 0) {
      // If no games were played, return the player's current rating
      return playerRating;
    }

    // Calculate the expected score
    const expectedScore = 1 / (1 + Math.pow(10, (opponentRating - playerRating) / 400));

    // Calculate the actual score based on wins, draws, and losses
    const actualScore = (wins + drafts * 0.5) / totalGames;

    // Calculate the new rating using the Elo formula
    const newRating = playerRating + this.K * (actualScore - expectedScore);

    return Math.ceil(newRating);
  };

}