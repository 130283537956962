import { Button, Card, CardActions, CardContent, Tooltip, Typography } from '@material-ui/core';
import { velas } from 'App/config';
import { Address } from 'components/Address';
import CustomButton from 'components/CustomButton';
import { GameBoardComponent } from 'components/GameBoard';
import { SvgIcon } from 'components/SvgIcon';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import { Game } from 'models/Game';
import * as React from 'react';
import { FaPlus } from 'react-icons/fa';
import { useAccount } from 'wagmi';
import Clock from '../../assets/imgs/clock.png';
import Vlx from '../../assets/imgs/vlx.png';
import "./style.scss";


interface GameCardProps {
  id: string;
  game: Game;
  preview?: boolean;
}



const GameCard: React.FC<GameCardProps> = observer(({ id, game, preview }) => {
  const { gameStore, navigate, gamesStore } = useStore();
  const { handleMouseEnter, handleMouseLeave } = gamesStore;
  const { isConnected, chain } = useAccount();
  const isWrongNetwork = chain?.id !== velas.id;
  const isNotAllowed = !isConnected || isWrongNetwork;

  const {
    hasDisplayedWinner,
    displayedWinner,
    displayedWinnerShort,
    hasWinner,
    isPrivate,
    betAmount,
    player1,
    player2,
    player1Short,
    player2Short,
    canJoinGame,
    canShowJoinGameButton,
    timeLimitMinutes,
    takingPart,
    isPlayer2Absent,
    gameLength,
    hasBet,
    claimBetAvailable,
    expectedPlayer2WasSet,
    showWinAndFinishedGameButton,
    showWinButton,
    checkAndEndGameWithWin,
    isDraft
  } = game;

  const handleJoin = () => {
    gameStore.currentGameId = id;
    game.joinGame();
  };

  const handleClaimBet = () => {
    gameStore.currentGameId = id;
    game.claimOwnBet();
  };

  const handleClaimWinnings = () => {
    gameStore.currentGameId = id;
    game.claimBets();
  };

  const onClick = (event: any) => {
    event?.stopPropagation();
    gameStore.currentGameId = id;
    navigate(`game/${id}`);
  }

  return (
    <Tooltip
      arrow
      open={preview !== undefined && gamesStore.hoveredGame?.id === id} 
      placement="left"
      title={preview ? <GameBoardComponent className='game-card-preview' /> : <></>}
    >
      <Card 
        onMouseEnter={handleMouseEnter(id)} 
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
        className='list-row game-card' 
        style={{ margin: '16px', padding: '16px', backgroundColor: '#f5f5f5' }}>
        {/* {isPrivate && (
          <SvgIcon classNames='lock' name="lock" width={50} fill='red'  />
        )} */}
        <CardContent className='list-row-body'>
          <Typography variant="body1" className='col-id list-cell'>
            {id}
          </Typography>
          <div className='col-player list-cell'>
            <Typography variant="body1">
              <Address value={player1Short} address={player1} />
            </Typography>
            <Typography variant="body1">
              {!isPlayer2Absent ? (
                <Address value={player2Short} address={player2} />
              ) : (
                <>
                  <img src={Clock} alt="timing" />
                  {expectedPlayer2WasSet ? (
                    <span>Waiting for opponent...</span>
                  ) : (
                    <span>--</span>
                  )}
                </>
              )}
            </Typography>
          </div>
          <Typography variant="body1" className='col-bets list-cell'>
            {betAmount > 0 ? (
              <><img src={Vlx} alt="vlx" />{betAmount} VLX</>
            ) : ("--")}
          </Typography>
          <Typography variant="body1" className='col-time list-cell'>
            <>
              <img src={Clock} alt="timing" /> {timeLimitMinutes} mins
            </>
          </Typography>
          <Typography variant="body1" className='col-gameSize list-cell'>
            <div className='game-size-container'>
              <span>{gameLength}</span>{<FaPlus className='rotate-45 cross-icon' />}<span>{gameLength}</span>
            </div>
          </Typography>
          <div className='col-winner list-cell'>
            {hasWinner || hasDisplayedWinner ? (
              <>
                <Typography variant="body1">
                  <Address value={displayedWinnerShort} address={displayedWinner} />
                </Typography>
                {hasBet && (
                  <Typography variant="body1">
                    <img src={Vlx} alt="vlx" />{betAmount * 2} VLX
                  </Typography>
                )}
              </>
            ) : (
                <Typography variant="body1" className=''>
                  {isDraft ? (
                    <div
                      className='label-draw'
                      >
                        Oops, game draw..
                      </div>
                    ) : (
                      "--"
                    )}
                </Typography>
            )}
          </div>
          <CardActions className='col-action list-cell'>
            {canJoinGame && (
              <CustomButton
                variant="contained"
                color="primary"
                onClick={handleJoin}
                disabled={game?.comeInButtonDisabled || isNotAllowed}
              >
                Join Game{' '}
                {isPrivate && (
                  <SvgIcon classNames='lock' name="lock" height={24} fill='red'  />
                )}
              </CustomButton>
            )}
            {canShowJoinGameButton && (
              <CustomButton
                variant="contained"
                color="primary"
                onClick={() => {}}
                disabled={true}
              >
                Join Game{' '}
                <SvgIcon classNames='lock' name="lock" height={24} fill='red'  />
              </CustomButton>
            )}
            {showWinButton && (
              <CustomButton
                variant="contained"
                color="primary"
                onClick={handleClaimWinnings}
                disabled={isNotAllowed}
              >Claim Win</CustomButton>
            )}
            {takingPart && claimBetAvailable && (
              <CustomButton
                variant="contained"
                color="primary"
                onClick={handleClaimBet}
                disabled={isNotAllowed}
              >
                Claim Bet
              </CustomButton>
            )}
            {showWinAndFinishedGameButton && hasBet && (
              <Button
                variant="contained"
                color="primary"
                onClick={checkAndEndGameWithWin}

              >
                Timeout Win
              </Button>
            )}
          </CardActions>
        </CardContent>

      </Card>
    </Tooltip>
  );
})

export default GameCard;