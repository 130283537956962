import { IconButton, Tooltip } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import Table from "components/Table";
import { useStore } from "hooks/useStore";
import React, { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import Clock from '../../assets/imgs/clock.png';
import Count from '../../assets/imgs/count.png';
import Player from '../../assets/imgs/player.png';
import Score from '../../assets/imgs/score.png';
import Win from '../../assets/imgs/win.png';


import { Address } from "components/Address";
import "./style.scss";


export const Leaderboard = () => {
  const { leaderboardStore, gamesStore } = useStore();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  
  const leadersArray = leaderboardStore.tableRowsData.map((data, index) => {
    return {
      "#": index + 1,
      Player: (
        <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
          <Address address={data.hexAddress} value={data.address} />
        </div>
      ),
      timeSpent: (
        <div>
          {data.timeSpent}
        </div>
      ),
      gamesCount: (
        <div>
          {data.gamesCount}
        </div>
      ),
      winPercentage: (
        <div>
          {data.winPercentage}%
        </div>
      ),
      wld: (
        <>
          <span>{data.wins}</span>/
          <span>{data.loses}</span>/
          <span>{data.draws}</span>
        </>
      ),
      Score: data.score,
    }
  });

  const filteredLeaders = gamesStore?.searchModel?.filterLeaderboard(leadersArray) || [];
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentLeaders = filteredLeaders.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      header: "#",
      flex: 0.3,
    },
    {
      header: (
        <>
          <img src={Player} alt="player" width={24}/>
          <p>Player</p>
        </>
      ),
      flex: 4,
    },
    {
      header: (
        <>
          <img src={Clock} alt="timing" width={20}/>
          <p>Time spent</p>
        </>
      ),
      flex: 2,
    },
    {
      header: (
        <>
          <img src={Count} alt="count" width={20}/>
          <p>Games count</p>
        </>
      ),
      flex: 2,
    },
    {
      header: (
        <>
          <img src={Win} alt="win" width={18}/>
          <p>Win percentage</p>
        </>
      ),
      flex: 2,
    },
    {
      header: (
        <>
          <span className="wld-container">
            <span className="wld-container-win">W</span>/
            <span className="wld-container-lose">L</span>/
            <span className="wld-container-draw">D</span>
          </span>
          <Tooltip title="Wins / Losses / Draws">
            <IconButton size="small" style={{ filter: 'none' }} className="info">
              <FaInfoCircle fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      ),
      flex: 1,
    },
    {
      header: (
        <>
          <img src={Score} alt="score" width={18}/>
          <p>Score</p>
        </>
      ),
      flex: 1,
    },
    
  ];

  return (
    <div className='leaderboard-table'>
      <div className='leaderboard-list'>
        {filteredLeaders.length > 0 ?
          <Table data={currentLeaders} columns={columns} />
          :
          <p className='container empty-state'>No result...</p>
        }
      </div>
      {filteredLeaders.length ? (
        <Pagination
          count={Math.ceil(filteredLeaders.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          className="pagination-block"
        />
      ) : null}
    </div>
  );
};