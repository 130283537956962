import { watchContractEvent } from '@wagmi/core';
import useNotifications from "hooks/useNotifications";

import { config, GameContract } from "App/config";
import { action, computed, makeObservable, observable } from "mobx";
import { Game } from "models/Game";
import { NavigateFunction } from "react-router-dom";
import { TransactionLog } from 'types';
import { useWriteContract, } from "wagmi";
import gameAbi from 'web3/contract/gameAbi';
import { BlockTimestampStore } from "./BlockTimestampStore";
import { GamesStore } from "./GamesStore";
import { GameStore } from "./GameStore";
import { LeaderboardStore } from "./LeaderboardStore";
import { MarqueeStore } from "./MarqueeStore";
import { MembersStore } from "./MembersStore";
import { NewGame } from "./NewGame";
import { ProfileStore } from "./ProfileStore";
import { TabStore } from './TabsStore';
import { ThemeStore } from "./ThemeStore";

type Fn = (logs: any) => void;

export class RootStore {
  GAME_LENGTH = 19;
  gamesStore: GamesStore;
  gameStore: GameStore;
  newGame: NewGame;
  blockTimestampStore: BlockTimestampStore;
  marqueeStore: MarqueeStore;
  profileStore: ProfileStore;
  membersStore: MembersStore;
  themeStore: ThemeStore;
  leaderboardStore: LeaderboardStore;
  tabsStore: TabStore;

  @observable contractEventWatchers = [] as Fn[];
  @observable writeContract: ReturnType<typeof useWriteContract> | undefined;
  @observable isConnected = false;


  navigate!: NavigateFunction;
  notify!: ReturnType<typeof useNotifications>;
  
  constructor() {
    makeObservable(this);
    this.gamesStore = new GamesStore(this);
    this.newGame = new NewGame(this);
    this.gameStore = new GameStore(this);
    this.blockTimestampStore = new BlockTimestampStore();
    this.profileStore = new ProfileStore(this);
    this.marqueeStore = new MarqueeStore(this);
    this.membersStore = new MembersStore(this);
    this.themeStore = new ThemeStore();
    this.leaderboardStore = new LeaderboardStore(this);
    this.tabsStore = new TabStore();
    this.init();
  }

  @action.bound
  init() {
    this.gamesStore.fetchAllGames();
    this.profileStore.fetchAllNickNames();
    // this.watchGameWonEvent();
    // this.watchMoveMadeEvent();
  }
  
  @computed 
  get myAddress() {
    return this.profileStore.myAddress;
  }

  @action.bound
  initNavigate(navigate: NavigateFunction) {
    this.navigate = navigate;
  };

  @action.bound
  initNotify(notify: ReturnType<typeof useNotifications>) {
    this.notify = notify;
  }

  @action.bound
  getGameById(id: string | null | undefined): Game | null | undefined {
    if (!id) {
      return null;
    }
    return this.gamesStore.gamesList.find(game => game.id === id);
  }

  @action.bound
  setConnectionStatus(status: boolean) {
    this.isConnected = status;
  }

  composeUseContractEvent(eventName: string, callbackEvent: any) {
    const unwatch = watchContractEvent(config, {
      address: GameContract,
      abi: gameAbi,
      eventName,
      onLogs: callbackEvent
    });
    console.log('composeUseContractEvent', unwatch);
    return unwatch;
  }

  watchGameWonEvent() {
    this.contractEventWatchers.push(this.composeUseContractEvent('GameWon', (logs: TransactionLog[]) => {
      console.log("contractEventWatchers [GameWon]", logs);
    }));
  }

  watchMoveMadeEvent() {
    this.contractEventWatchers.push(this.composeUseContractEvent('MoveMade', (logs: TransactionLog[]) => {
      console.log("contractEventWatchers [MoveMade]", logs);
    }));
  }

  // gameWonCallback(logs: any){
  //   console.log("gameWonCallback", logs);
  // }
}

export const store = new RootStore();

